@import '../../../styles/customMediaQueries.css';

.root {
  height: 80%;
@media (--viewportMaxLarge) {
  height: auto;
}
}

.content {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
  height: 100%;

  @media (--viewportMaxMedium) {
    display: grid;
  }
}

.avatar {
  flex-shrink: 0;
}

.info {
  flex: 1;
}

.heading {
  composes: p from global;
  margin: 0;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.link {
  white-space: nowrap;
}

.mobileBio,
.desktopBio {
  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;
  margin: 18px 0 0 0;
  font-family: var(--fontFamilyHelmet);
  font-size: var(--fontSizeSubText) !important;
  font-weight: 400;
  line-height: normal;
  text-align: left;
  color: black;
  transition: all 1s ease-in;
  margin: 0;
  margin-bottom: 10px;
  @media (--viewportMaxMedium){
    text-align: center;
  }

}

.mobileBio {
  display: none;
  @media (--viewportMaxMedium) {
    display: block;
  }
}

.desktopBio {
  font-family: var(--fontFamilyHelmet);
  font-size: var(--fontSizeSubHeading);
  font-weight: 400;
  line-height: 24.8px;
  text-align: left;
  color: black;
  display:block;

  @media (--viewportMedium) {
    font-family: var(--fontFamilyHelmet);
    font-size: var(--fontSizeDescription);
    font-weight: 400;
    line-height: 24.8px;
    text-align: left;
    color: black;

    &::before {
      content: ' ';
    }
  }
  @media (--viewportMaxMedium) {
    display: none;
  }
}

.longWord {
  word-break: break-word;
  hyphens: auto;
}

.showMore {
  /* Position and dimensions */
  display: inline;
  padding: 0;

  /* Borders */
  border: none;

  /* Colors */
  color: var(--marketplaceColor);

  /* Text size should be inherited */
  text-decoration: none;

  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: var(--lineHeightBase);
  letter-spacing: 0;

  margin: 0 0 0 5px;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (--viewportMedium) {
    font-weight: var(--fontWeightMedium);
    /* margin-top + n * line-height + margin-bottom => x * 8px */
    margin-top: 17px;
    margin-bottom: 15px;
  }
}

.links {
  margin-top: 0px;
  margin: 0px;
  font-family: var(--fontFamilyHelmet);
  font-size: var(--fontSizeBase);
  font-weight: 400;
  line-height: var(--lineHeightBase);
  text-align: left;
  @media (--viewportMaxMedium){
    text-align: center;
  }

  @media (--viewportLarge) {
    margin: 10px 0 0 0;
    font-size: var(--fontSizeSubHeading);
  }
}

.withBioMissingAbove {
  @media (--viewportMedium) {
    /* Fix baseline alignment if bio is missing from above */
    margin: 0px;
  }
}

.linkSeparator {
  color: var(--marketplaceColor);
}

.headingRow {
  display: flex;
  font-family: var(--fontFamilyBethanyElingston);
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  font-size: var(--fontSizeHeading);
  font-weight: 400;
  line-height: normal;
  text-align: left;
  color: black;

  @media (--viewportMaxMedium) {
    flex-direction: column;
    font-size: var(--fontSizeSubHeading);
    font-weight: 400;
    line-height: 24.8px;
    justify-content: center;
    align-items: center;
  }
  @media (--viewportMaxMedium) {
    text-align: center !important;
    margin-top: 5px;
  }
}

.editProfileDesktop {
  display: inline;
  padding: 0;
  font-family: var(--fontFamilyHelmet);
  font-weight: 400;
  line-height: 18.6px;
  text-align: left;

  /* Borders */
  border: none;

  /* Colors */
  color: var(--marketplaceColor);

  /* Text size should be inherited */
  text-decoration: none;

  font-weight: var(--fontWeightMedium);
  font-size: var(--fontSizeBase);
  line-height: var(--lineHeightBase);
  letter-spacing: 0;
  margin: 0;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (--viewportLarge) {
    font-weight: 400;
    margin: 0;
    font-size: var(--fontSizeSubHeading);
  }
}

.editProfileMobile {
  display: inline;
  padding: 0;
  font-family: var(--fontFamilyHelmet);
  font-weight: 400;
  line-height: 18.6px;
  text-align: left;

  /* Borders */
  border: none;

  /* Colors */
  color: var(--marketplaceColor);

  /* Text size should be inherited */
  text-decoration: none;

  font-weight: var(--fontWeightMedium);
  font-size: var(--fontSizeBase);
  line-height: var(--lineHeightBase);
  letter-spacing: 0;
  margin: 0;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (--viewportLarge) {
    font-weight: 400;
    margin: 0;
    font-size: var(--fontSizeSubHeading);
  }
}

.contact {
  /* Position and dimensions */
  display: inline;
  padding: 0;
  font-family: var(--fontFamilyHelmet);
  font-weight: 400;
  line-height: 18.6px;
  text-align: left;

  /* Borders */
  border: none;

  /* Colors */
  color: var(--marketplaceColor);

  /* Text size should be inherited */
  text-decoration: none;

  font-weight: var(--fontWeightMedium);
  font-size: var(--fontSizeBase);
  line-height: var(--lineHeightBase);
  letter-spacing: 0;
  margin: 0;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (--viewportLarge) {
    font-weight: 400;
    margin: 0;
    font-size: var(--fontSizeSubHeading);
  }
}

.contact span {
  font-weight: 400;
}

.favouriteBtn {
  width: auto !important;
  height: 30px !important;
  border-radius: 100%;
  padding: 0 6px;
  background: #fff;
  box-shadow: none;
  border: 1px solid black;
  cursor: pointer;
}

.favouriteBtn:hover svg path {
  fill: black !important;
}

.heartSvg {
  width: 16px;
  height: 16px;
  margin: 0 auto;
}

.heartSvg path {
  stroke: #973132 !important;
}

.likedSvg path {
  fill: #973132 !important;
}

.avatarMain {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.favouriteBtn {
  position: absolute;
  bottom: -10px;
}